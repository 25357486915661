import {Geolocation, Geoposition} from '@ionic-native/geolocation';
import React, {useState} from "react";
import { IonFooter, IonModal,IonIcon, IonButton, IonSplitPane, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import GeoLocationButton from '../components/GeoLocationButton';
import { logoIonic, mapSharp, locationSharp } from 'ionicons/icons';
import './Home.css';
import { Preferences } from '@capacitor/preferences';



//REACT RUNS ALL FUNCTIONS ON THE PAGE INCLUDING THE RENDERED ELEMENT
//REACT TEMPLATES SUPPORT REGULAR JAVASCRIPT IN THE SAME FORM CALLED IN AN HTML
//alert("running homepage");
structureformload();


const Home: React.FC = () => {
  return (
    <IonPage><IonHeader className="GLIHEADER2" />
          <IonContent><div><iframe className="GLIFRAME2" src="https://www.glocation.info/fusionpro/framebase.html"></iframe></div>
	</IonContent> 
  <IonToolbar className="GLIFOOTER2"><IonButton className="GLILOCBUTTON2" routerLink="/Geo" color="success" slot="start"><IonIcon icon={mapSharp}></IonIcon>GeoHome</IonButton>

          <IonTitle className="GLIFOOTER2">@Velocity.Pad [V6.4.1 Dock]</IonTitle><GeoLocationButton/ >
          </IonToolbar>   
</IonPage>
  );
};

export default Home;

async function structureformload()
{
           //alert("This is a function test");
           const position = await Geolocation.getCurrentPosition();    
           const stringLat  = String(position.coords.latitude);
           const stringLong = String(position.coords.longitude);
           const StringLocation = stringLat + "," + stringLong;
           let timenow = "";
           timenow = Date();
           localStorage.setItem("LAT", stringLat);
           localStorage.setItem("LONG", stringLong);
           localStorage.setItem("jid", "901");
           localStorage.setItem("jtime", timenow);
           localStorage.setItem("userlocation", StringLocation);
           localStorage.setItem("jstatus", "loggedin")
           const MapLocation = 'https://mapquest.com/search/' + stringLat + "," + stringLong;
           localStorage.setItem("Mapquest", MapLocation);
           alert("Welcome to Glocation.info. Your Location is: " + position.coords.latitude + "," + position.coords.longitude); 
           window.open('https://circusapp.glocation.info/session/sessionjidupdate.html', '_blank');
}
